<template>
  <el-form
    class="form"
    ref="formData"
    :model="formData.EnterpriseInfo"
    :rules="rules"
    size="medium"
    label-width="110px"
    :disabled="isView"
  >
    <el-form-item label="公司全称" prop="EnterpriseName">
      <el-input
        :disabled="isView || isEdit"
        v-model="formData.EnterpriseInfo.EnterpriseName"
        placeholder="请填写公司名称"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="信用代码" prop="Code">
      <el-input
        v-model="formData.EnterpriseInfo.Code"
        placeholder="请填写信用代码"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-form-item label="公司logo" prop="Logo">
      <picture-selector
        v-model="formData.EnterpriseInfo.Logo"
        name="Logo"
        ref="Logo"
        remarks="200*200"
        uploadPath="/File/UploadSingleFile"
        :width="100"
        :height="100"
        @input="updateImg"
      >
      </picture-selector>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :span="12">
        <el-form-item label="公司行业" prop="Industry">
          <el-cascader
            v-model="formData.EnterpriseInfo.Industry"
            :show-all-levels="false"
            :props="industryProps"
            :options="industryOptions"
            style="width:100%"
            placeholder="请选择所属行业"
          >
          </el-cascader>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="企业性质" prop="Nature">
          <el-select
            v-model="formData.EnterpriseInfo.Nature"
            placeholder="请选择"
            class="width100"
          >
            <el-option
              v-for="item in natureOptions"
              :key="item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <el-form-item label="公司规模" prop="Scale">
          <el-select
            v-model="formData.EnterpriseInfo.Scale"
            placeholder="请选择"
            class="width100"
          >
            <el-option
              v-for="item in scaleOptions"
              :key="item.Id"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="所在区域" prop="Area">
          <el-cascader
            v-model="formData.EnterpriseInfo.Area"
            :props="propsArea"
            :options="areaTree"
            class="width100"
            placeholder="请选择所属地区"
            @change="areaChange"
          >
          </el-cascader>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="详细地址" prop="Address">
      <el-input
        v-model="formData.EnterpriseInfo.Address"
        placeholder="请填写详细地址"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <el-row :gutter="24">
      <el-col :span="12">
        <el-form-item label="校企合作联系人" prop="CooperationMan">
          <el-input v-model="formData.EnterpriseInfo.CooperationMan"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="校企合作电话" prop="CooperationPhone">
          <el-input
            v-model="formData.EnterpriseInfo.CooperationPhone"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <el-form-item label="校企合作微信" prop="CooperationWechat">
          <el-input
            v-model="formData.EnterpriseInfo.CooperationWechat"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12"> </el-col>
    </el-row>
    <!-- <el-form-item label="宣传视频">
            <upload-material v-if="formData.Id" @input="uploadedResourse" :limit-count="1" :show-file="true" :value="formData.PromotionalVideo" limit-type="video/mp4" accept=".mp4" remark-text="只能上传mp4格式的视频文件">
            </upload-material>
          </el-form-item> -->
    <el-form-item
      v-if="isCreate || formData.EnterpriseInfo.Introduction"
      label="公司简介"
    >
      <rich-text
        v-model="formData.EnterpriseInfo.Introduction"
        name="Introduction"
      ></rich-text>
    </el-form-item>
    <el-row v-if="!isView">
      <el-col :span="24">
        <el-form-item size="large">
          <el-button
            type="primary"
            v-if="!isView"
            @click="submitForm"
            :loading="submitting"
            >确认</el-button
          >
          <el-button @click="cancle" :loading="submitting">返回</el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import PictureSelector from "@/components/base/PictureSelector";
import { mapGetters } from "vuex";
import {
  getAttributeOptions,
  updateEntity
} from "@/api/enterprise/enterpriseInfo";
import { getInfo, insert, edit } from "@/api/schoolenterprise";
import { searchEnterpriseBaseList } from "@/api/enterprise/enterpriseInfo";
import { CooperationTypeEnum, StaffTypeEnum } from "@/utils/constant";
import { isPhone } from "@/utils/validate";
export default {
  components: { PictureSelector },
  props: {
    id: {
      type: String,
      default: ""
    },
    isCreate: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myIntroduction: null,
      industryOptions: [],
      natureOptions: [],
      scaleOptions: [],
      areaTree: [],

      industryProps: {
        emitPath: false,
        value: "Id",
        label: "Name",
        children: "Children"
      },
      propsArea: {
        value: "Code",
        label: "Name",
        children: "Children"
      },
      enterpriseId: this.$route.query.id || undefined,
      formData: {
        Type: CooperationTypeEnum.SchoolToEnterprise,
        Name: "",
        EnterpriseInfo: {
          EnterpriseBaseId: "",
          EnterpriseName: "",
          Code: "",
          Logo: "",
          Industry: "",
          Nature: "",
          Scale: "",
          ProvinceCode: "",
          CityCode: "",
          RegionCode: "",
          BusinessLicense: "",
          Status: 0,
          Registered: true,
          EstablishTime: "",
          LogoLong: "",
          Summary: "",
          Introduction: "",
          Longitude: 0,
          Latitude: 0,
          Phone: "",
          Postcode: "",
          Fax: "",
          Email: "",
          Address: "",
          ModifyContent: "",
          CooperationMan: "",
          CooperationPhone: "",
          CooperationWechat: "",
          PromotionalVideo: "",
          ResourceInvestment: "",
          ProductionService: "",
          PersonnelTraining: "",
          DevelopmentPath: "",
          Area: []
        }
      },
      rules: {
        Name: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Email: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        EnterpriseName: [
          {
            required: true,
            message: "此项为必填项",
            trigger: ["blur", "change"]
          }
        ],
        BusinessLicense: [
          { required: true, message: "请上传营业执照", trigger: "blur" }
        ],
        IdNumber: [
          { required: true, message: "此项为必填项", trigger: "blur" }
        ],
        // Code: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Logo: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Nature: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Industry: [
          { required: true, message: "此项为必填项", trigger: "blur" }
        ],
        Area: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Address: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Scale: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        RealName: [{ required: true, message: "此项为必填项", trigger: "blur" }]
      },
      submitting: false,
      loaded: false
    };
  },
  computed: {
    ...mapGetters(["employee"])
  },
  created() {
    this.init();
  },
  mounted() {
    getAttributeOptions().then(res => {
      if (res.IsSuccess) {
        this.industryOptions = res.Result.industryOptions;
        this.natureOptions = res.Result.natureOptions;
        this.scaleOptions = res.Result.scaleOptions;
        this.areaTree = this.deleteChildren(res.Result.areaTree.Children);
        this.isLoaded = true;
      }
    });
  },
  methods: {
    async init() {
      if ((this.isView || this.isEdit) && this.enterpriseId) {
        const { Result } = await getInfo(this.enterpriseId);
        console.log(Result);
        if (this.isView) {
          this.formData.EnterpriseInfo = Object.assign(
            {},
            this.formData.EnterpriseInfo,
            Result.EnterpriseInfo,
            {
              Name: Result.EnterpriseName
            }
          );
        } else if (this.isEdit) {
          for (const i in this.formData.EnterpriseInfo) {
            if (i) {
              if (!Result.EnterpriseInfo[i]) Result.EnterpriseInfo[i] = "";
              this.formData.EnterpriseInfo[i] = Result.EnterpriseInfo[i] + "";
            }
          }
          this.formData.EnterpriseInfo = {
            ...this.formData.EnterpriseInfo,
            Area: []
          };
          this.formData.EnterpriseInfo.Area[0] =
            Result.EnterpriseInfo.ProvinceCode;
          this.formData.EnterpriseInfo.Area[1] = Result.EnterpriseInfo.CityCode;
          this.formData.EnterpriseInfo.Area[2] =
            Result.EnterpriseInfo.RegionCode;
          this.formData.EnterpriseInfo.Status = 0;
          this.formData.EnterpriseInfo.Registered = true;
          this.formData.EnterpriseInfo.Longitude = 0;
          this.formData.EnterpriseInfo.Latitude = 0;
          this.formData.EnterpriseInfo.Id = Result.EnterpriseInfo.Id;
          this.formData.Id = Result.Id;
        }
      } else {
        const { Employer: { Id } = {} } =
          this.employee.find(
            ({ StaffType }) => StaffType === StaffTypeEnum.School
          ) || {};

        this.formData.SchoolId = Id;
        this.loaded = true;
      }
      this.$nextTick(_ => {
        this.$refs["formData"].clearValidate();
      });
    },
    // async searchEnterprise(query, cb) {
    //   if (query) {
    //     const { Result } = await searchEnterpriseBaseList(query);
    //     cb(
    //       Result.map(({ Text }) => ({
    //         value: Text
    //       }))
    //     );
    //   } else {
    //     cb([]);
    //   }
    // },
    submitForm() {
      const { Employer: { Id } = {} } =
        this.employee.find(
          ({ StaffType }) => StaffType === StaffTypeEnum.School
        ) || {};
      this.formData.SchoolId = Id;
      console.log(this.formData.EnterpriseInfo.Area);
      if (!this.formData.SchoolId) {
        this.$message.error("新增失败，请稍后重试");
        return;
      }
      this.$refs["formData"].validate(msg => {
        if (!msg) {
          return;
        }

        this.submitting = true;
        // this.formData.EnterpriseInfo.EnterpriseName;
        this.formData.EnterpriseInfo.ProvinceCode = this.formData.EnterpriseInfo.Area[0];
        this.formData.EnterpriseInfo.CityCode = this.formData.EnterpriseInfo.Area[1];
        this.formData.EnterpriseInfo.RegionCode = this.formData.EnterpriseInfo.Area[2];
        this.formData.Name = this.formData.EnterpriseInfo.EnterpriseName;
        this.formData.EnterpriseInfo.Area = undefined;
        const createData = Object.assign({}, this.formData);
        const editData = Object.assign({}, this.formData, {
          Phone: this.formData.EnterpriseInfo.EnterprisePhone,
          Contacts: this.formData.EnterpriseInfo.EnterpriseContacts,
          //todo:更新的时候只更新对方的联系方式，所以需要将类型转换
          Type: CooperationTypeEnum.SchoolToEnterprise
        });
        const task = this.isEdit ? edit(editData) : insert(createData);
        task
          .then(_ => {
            this.submitting = false;
            this.$router.push("/school/enterpriselist");
          })
          .catch(_ => {
            this.submitting = false;
          });
      });
    },
    deallogo(fieldName, value) {
      this.formData.EnterpriseInfo[fieldName] = value;
    },
    dealDescription(fieldName, value) {
      this.formData.EnterpriseInfo[fieldName] = value;
    },
    updateImg(name, file) {
      console.log(file);
      this.formData.EnterpriseInfo.Logo = file;
    },
    cancle() {
      if (!this.isView) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "温馨提示"
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.$router.go(-1);
      }
    },
    updateForm(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.companyForm[fieldName] = value;
    },
    areaChange(value) {
      this.formData.EnterpriseInfo.ProvinceCode = value[0];
      this.formData.EnterpriseInfo.CityCode = value[1];
      this.formData.EnterpriseInfo.RegionCode = value[2];
    },
    deleteChildren(arr) {
      const childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].Children) {
          if (childs[i].Children.length) {
            this.deleteChildren(childs[i].Children);
          } else {
            delete childs[i].Children;
          }
        }
      }
      return arr;
    }
  }
};
</script>
<style lang="scss" scoped>
.form {
  background: #fff;
  padding: 48px;
  margin-bottom: 20px;
  border-radius: 8px;
  .to {
    ::v-deep label {
      &::before {
        display: none;
      }
    }
  }
}</style
>>
